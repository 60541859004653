/* eslint-disable no-unused-vars */
export const fetchChatToken = async () => {
  // const func = functions.httpsCallable('fetchChatToken');
  // const response = await func();
  // return response.data;
};

export const createConversation = async identity => {
  // const func = functions.httpsCallable('createConversation');
  // const response = await func({ identity });
  // return response.data;
};

// TODO: Ubsubscribe at signout
export const onShopChanged = async (adminUid, cb) => {};
