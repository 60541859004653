import { Button as GrommetButton } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RoutedButton = ({ href, onClick, ...props }) => {
  const history = useHistory();
  const handleClick = e => {
    e.preventDefault();
    history.push(href);
  };
  return (
    <GrommetButton onClick={onClick || handleClick} href={href} {...props} />
  );
};

RoutedButton.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
};

RoutedButton.defaultProps = {
  href: undefined,
  onClick: undefined,
};

export default RoutedButton;
