import { atom } from 'recoil';

const ChatState = atom({
  key: 'ChatState',
  default: {
    connection: 'disconnected',
    conversations: [],
  },
});

export default ChatState;
