/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { conversationShape } from '../types';

import Body from './Body';
import ChatInput from './ChatInput';
import Message from './Message';
import Scrollable from './Scrollable';

const ChatBox = ({
  conversation, onSend, notifyReceipt,
}) => {
  const [input, setInput] = useState('');
  const bottomRef = useRef(null);
  const { messages, participant } = conversation || {};

  const scrollToBottom = behavior => bottomRef.current.scrollIntoView({ behavior });

  useEffect(() => {
    scrollToBottom('auto');
  }, []);

  useEffect(() => {
    if (messages?.length > 0) {
      scrollToBottom('smooth');
      notifyReceipt();
    }
  }, [messages?.length, notifyReceipt]);

  const handleChange = ({ target }) => {
    setInput(target.value);
  };

  const getTimestamp = timestamp => {
    const date = dayjs(timestamp);
    return date.isSame(dayjs(), 'day')
      ? date.format('HH:mm')
      : date.format('D/M/YY');
  };

  const handleSend = async () => {
    const inputWithoutSpaces = input.split(' ').join('');
    if (!!input && inputWithoutSpaces.length) {
      onSend(input);
      setInput('');
    }
  };

  return (
    <>
      <Scrollable background="light-1" fill>
        <Body margin="large">
          {messages?.map(message => (
            <Box
              flex={false}
              key={message.sid}
              margin={{ bottom: 'small' }}
            >
              <Message
                message={message.body}
                timestamp={getTimestamp(message.dateCreated)}
                isIncoming={message.participant === participant?.sid}
                sent
              />
            </Box>
          ))}
          <div ref={bottomRef} />
        </Body>
      </Scrollable>
      <ChatInput
        placeholder="Escribe tu mensaje..."
        value={input}
        onChange={handleChange}
        onSend={handleSend}
      />
    </>
  );
};

ChatBox.propTypes = {
  conversation: PropTypes.shape(conversationShape),
  onSend: PropTypes.func.isRequired,
  notifyReceipt: PropTypes.func.isRequired,
};

ChatBox.defaultProps = {
  conversation: undefined,
};

export default ChatBox;
