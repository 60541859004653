/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_STORE_SETTING = gql`
query ($userId: String!) {
  store (query: {owner_id: $userId} ) {
    name
  }
  profile (query: {user_id: $userId}) {
    publicName
    photo
  }
}
`;
