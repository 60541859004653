import { Box, Text } from 'grommet';
import { FormClock, FormCheckmark } from 'grommet-icons';
import PropTypes from 'prop-types';
import React from 'react';

const Message = ({
  message, isIncoming, timestamp, sent,
}) => (
  <Box flex={false}>
    <Box
      background="white"
      width={{ max: '80%' }}
      pad="medium"
      round={{
        size: '10px',
        corner: isIncoming ? 'right' : 'left',
      }}
      border={{
        color: isIncoming ? 'brand' : 'light-6',
        size: 'medium',
        style: 'solid',
        side: isIncoming ? 'left' : 'right',
      }}
      alignSelf={isIncoming ? 'start' : 'end'}
    >
      <Text size="medium" wordBreak="break-word">
        {message}
      </Text>
    </Box>

    <Box
      margin={{ top: 'xsmall' }}
      direction="row"
      justify={isIncoming ? 'start' : 'end'}
    >
      <Text color="text-xweak" size="xsmall">
        {timestamp}
      </Text>
      <Box margin={{ vertical: 'auto' }}>
        {!isIncoming && !sent && (
        <FormClock color="text-xweak" size="small" />
        )}
        {!isIncoming && sent && (
        <FormCheckmark color="text-xweak" size="small" />
        )}
      </Box>
    </Box>
  </Box>
);

Message.propTypes = {
  message: PropTypes.string.isRequired,
  isIncoming: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
  sent: PropTypes.bool.isRequired,
};

export default Message;
