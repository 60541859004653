import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const Frame = ({ children, ...props }) => (
  <Box
    background="#f7f7f7"
    height="100%"
    {...props}
  >
    {children}
  </Box>
);

Frame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Frame;
