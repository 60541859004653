import {
  Box, Avatar, Text, Card,
} from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

import defaultAvatar from '../assets/default-avatar.png';

import RoutedButton from './RoutedButton';

const ProfileCard = ({
  avatar,
  name,
  subheader,
}) => (
  <Card
    flex={false}
    background="white"
    pad="medium"
    direction="row"
    gap="medium"
  >
    <Avatar flex={false} src={avatar || defaultAvatar} size="large" />
    <Box basis="full" justify="between">
      <Box>
        <Text
          truncate
          weight="bold"
          size="large"
        >
          {name}
        </Text>
        <Text
          truncate
          weight="bold"
          size="small"
          color="dark-3"
        >
          {subheader}
        </Text>
      </Box>
      <RoutedButton
        primary
        label="Personalizar"
        href="/dashboard/edit-profile"
      />
    </Box>
  </Card>
);

ProfileCard.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
};

ProfileCard.defaultProps = {
  avatar: '',
};

export default ProfileCard;
