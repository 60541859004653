import {
  Box, Button, Heading, TextInput,
} from 'grommet';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Realm from 'realm-web';

import Body from '../../components/Body';
import ErrorBox from '../../components/ErrorBox';
import FlatBackTopbar from '../../components/FlatBackTopbar';
import Layout from '../../components/Layout';
import PasswordInput from '../../components/PasswordInput';
import RoutedButton from '../../components/RoutedButton';
import realmApp from '../../services/realm';

const signUpErrors = {
  BadRequest: 'Debes introducir un correo valido y una contraseña con minimo 6 caracteres',
  AccountNameInUse: 'Ese correo ya esta registrado',
};

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSignUp = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      await realmApp.emailPasswordAuth.registerUser(email, password);
      const credentials = Realm.Credentials.emailPassword(email, password);
      await realmApp.logIn(credentials);
      history.push('/');
    } catch (e) {
      setError(signUpErrors[e.errorCode]);
      setLoading(false);
    }
  };

  return (
    <Layout topbarComponent={<FlatBackTopbar />}>
      <Body gap="large">
        <Heading level={1} size="large">Regístrate</Heading>
        <form onSubmit={handleSignUp}>
          <Box gap="medium" flex={false}>
            {error && <ErrorBox message={error} />}
            <TextInput
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Correo electrónico"
              aria-label="Correo electrónico"
            />
            <PasswordInput
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Contraseña"
              aria-label="Contraseña"
            />
            <Button
              primary
              label="Crear cuenta"
              type="submit"
              disabled={loading}
            />
          </Box>
        </form>
        <Box
          direction="row"
          margin={{ vertical: 'xlarge' }}
          justify="center"
          gap="xsmall"
          flex={false}
        >
          ¿Ya tienes cuenta?
          <RoutedButton
            label="Inicia sesión"
            href="/auth/signin"
          />
        </Box>
      </Body>

    </Layout>
  );
};

export default SignUp;
