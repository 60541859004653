import { Blank } from 'grommet-icons';
import nop from 'nop';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { X } from 'react-feather';
import shortid from 'shortid';
import styled from 'styled-components';

import RoutedButton from './RoutedButton';

const {
  Text, Card, Table, TableHeader, TableRow, TableCell,
  TableBody, TableFooter, ThemeContext, Box,
} = require('grommet');

const RemoveCell = styled(TableCell)`
  padding: 0;
  margin: 0;

  svg {
    vertical-align: text-bottom;
  }
`;

const Receipt = ({ items, onRemoveLine }) => {
  const total = useMemo(() => items.reduce((a, b) => a + b.price, 0).toFixed(2), [items]);

  return (
    <Card
      flex={false}
      background="white"
      margin={{ top: 'large' }}
      pad="small"
    >
      <ThemeContext.Extend
        value={{
          table: {
            body: {
              pad: { vertical: 'xsmall' },
            },
            footer: {
              pad: { top: '15px' },
              border: 0,
            },
          },
        }}
      >
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell size="100%" scope="col">
                <Text weight="bold" color="dark-3" size="xsmall">PRODUCTO</Text>
              </TableCell>
              <TableCell scope="col">
                <Text weight="bold" color="dark-3" size="xsmall">CANT</Text>
              </TableCell>
              <TableCell scope="col" colSpan={1}>
                <Text weight="bold" color="dark-3" size="xsmall">PRECIO</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            { items.map((item, index) => (
              <TableRow key={shortid.generate()}>
                <TableCell scope="row">
                  <Text size="small">
                    {item.name}
                  </Text>
                </TableCell>
                <TableCell align="center">
                  <Text size="small">
                    {item.qty}
                  </Text>
                </TableCell>
                <TableCell align="right">
                  <Text size="small">
                    {item.price.toFixed(2)}
                    €
                  </Text>
                </TableCell>
                <RemoveCell align="center">
                  <RoutedButton
                    onClick={() => onRemoveLine(index)}
                    icon={(
                      <Blank color="status-error" size="small">
                        <X color="" />
                      </Blank>
                    )}
                  />
                </RemoveCell>
              </TableRow>
            ))}

          </TableBody>
          <TableFooter pad={{ veetical: 'large' }}>

            <TableRow>
              <TableCell colSpan={3}>
                <Box gap="small" direction="row" justify="end">

                  <Text weight="bold">
                    Total
                  </Text>
                  <Text weight="bold">
                    {total}
                    €
                  </Text>
                </Box>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </ThemeContext.Extend>
    </Card>
  );
};

Receipt.propTypes = {
  onRemoveLine: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    qty: PropTypes.number,
    name: PropTypes.name,
    price: PropTypes.number,
  })).isRequired,
};

Receipt.defaultProps = {
  onRemoveLine: nop,
};

export default Receipt;
