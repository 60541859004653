import {
  Box, Button, Heading, TextInput,
} from 'grommet';
import React from 'react';

import BackButton from '../../components/BackButton';
import Body from '../../components/Body';
import Frame from '../../components/Frame';
import Receipt from '../../components/Receipt';
import RoutedButton from '../../components/RoutedButton';
import Scrollable from '../../components/Scrollable';
import Topbar from '../../components/Topbar';

const SendCheckout = () => {
  const handleAdd = () => {};
  const handleSubmit = () => {};
  // const handleRemoveLine = () => {};

  return (
    <Frame>
      <Topbar background="white">
        <Topbar.Left>
          <BackButton />
        </Topbar.Left>
      </Topbar>
      <Scrollable>
        <Body gap="large">
          <Heading level={1} size="large">Solicitar Pago</Heading>
          <Box
            flex={false}
            gap="medium"
          >
            <TextInput
              maxLength={50}
              aria-label="Producto"
              placeholder="Producto"
            />
            <Box direction="row" gap="small">
              <Box basis="1/4">
                <TextInput
                  aria-label="Cantidad"
                  placeholder="Cant."
                  type="number"
                />

              </Box>
              <Box basis="1/4">
                <TextInput
                  type="number"
                  aria-label="Precio"
                  placeholder="Precio"
                />
              </Box>
              <Box basis="2/4">
                <Button
                  primary
                  onClick={handleAdd}
                  label="Añadir al recibo"
                />
              </Box>
            </Box>

          </Box>
          <Receipt
            vendor="PESCADERIA"
            items={[
              { qty: 1, name: 'Gambas de sanlucar', price: 8.2 },
              { qty: 2, name: 'Pulpo fresco', price: 12 },
              { qty: 1.5, name: 'Almejas de corral', price: 5.5 },
            ]}
          />
          <RoutedButton primary label="Solicitar pago" onClick={handleSubmit} />
        </Body>
      </Scrollable>
    </Frame>
  );
};

export default SendCheckout;
