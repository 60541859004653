import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { createConversation } from '../services/api';
import twilioConversations from '../services/twilio';

import { ConversationWithParticipant } from './selectors';

const useConversation = identity => {
  const conversation = useRecoilValue(ConversationWithParticipant(identity));

  const handleSendMessage = useCallback(async body => {
    let conversationSid = conversation?.sid;
    if (!conversationSid) {
      conversationSid = await createConversation(identity);
    }
    const conversationEntity = await twilioConversations.client
      .getConversationBySid(conversationSid);
    return conversationEntity.sendMessage(body);
  }, [conversation, identity]);

  return [conversation, handleSendMessage];
};

export default useConversation;
