/* eslint-disable no-unused-vars */
import { Box, Heading, Layer } from 'grommet';
import { Blank } from 'grommet-icons';
import { MoreVertical } from 'react-feather';

import { useConversationlList } from '../../Chat';
import noMessagesImage from '../../assets/no-messages.svg';
import Announcement from '../../components/Announcement';
import Body from '../../components/Body';
import ChatContact from '../../components/ChatContact';
import IconButton from '../../components/IconButton';
import Layout from '../../components/Layout';
import RoutedButton from '../../components/RoutedButton';
import SignOutTopbar from '../../components/SignOutTopbar';
import Topbar from '../../components/Topbar';

const ConversationList = () => {
  const conversations = useConversationlList();

  return (
    <Layout
      navbar
      topbarComponent={<SignOutTopbar />}
    >
      <Body>
        <Heading level={1} size="large">Mensajes</Heading>
        <Box gap="medium">
          {conversations.map(({
            sid,
            participant,
            messages,
          }) => (
            <RoutedButton key={sid} href={`/messages/${participant.identity}`}>
              <ChatContact
                name={participant.name}
              // unreadMessagesCount={unreadMessagesCount}
                lastMessage={messages[messages.length - 1].body}
              />
            </RoutedButton>
          ))}
        </Box>

        {!conversations.length && (
          <Box fill justify="center">
            <Announcement
              margin="auto"
              message="Aun no tienes mensajes"
              image={noMessagesImage}
            />
          </Box>
        )}
      </Body>
    </Layout>
  );
};

export default ConversationList;
