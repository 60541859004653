import { Main } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const Scrollable = ({ children, ...props }) => (
  <Main direction="column" flex {...props}>
    {children}
  </Main>
);

Scrollable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Scrollable;
