import { Box, Avatar, Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

import defaultAvatar from '../assets/default-avatar.png';

import Badge from './Badge';

const ChatContact = ({
  avatar,
  name,
  lastMessage,
  unreadMessagesCount,
  ...props
}) => (
  <Box gap="medium" direction="row" {...props}>
    <Avatar flex={false} src={avatar} />
    <Box margin="auto" fill="horizontal">
      <Text color="text" size="large" truncate>
        {name}
      </Text>
      <Text color="dark-5" weight={400} truncate>
        {lastMessage}
      </Text>
    </Box>
    <Box flex={false} basis="30px">
      {unreadMessagesCount > 0 && (
      <Badge
        text={unreadMessagesCount > 99
          ? '!' : unreadMessagesCount}
        margin="auto"
      />
      )}
    </Box>
  </Box>
);

ChatContact.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  lastMessage: PropTypes.string,
  unreadMessagesCount: PropTypes.number,
};

ChatContact.defaultProps = {
  avatar: defaultAvatar,
  lastMessage: '',
  unreadMessagesCount: 0,
};

export default ChatContact;
