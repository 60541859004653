import { Box, Button, DropButton } from 'grommet';
import { Blank } from 'grommet-icons';
import { MoreVertical } from 'react-feather';
import { useHistory } from 'react-router-dom';

import apolloClient from '../services/apollo';
import realmApp from '../services/realm';

import Topbar from './Topbar';

const SignOutTopbar = () => {
  const history = useHistory();

  const handleSignOut = async () => {
    const user = realmApp.currentUser;
    realmApp.removeUser(user);
    apolloClient.clearStore();
    history.push('/auth/signin');
  };

  return (
    <Topbar>
      <Topbar.Right>
        <Box round="full" overflow="hidden">
          <DropButton
            primary
            label={(
              <Blank color="white">
                <MoreVertical color="" />
              </Blank>
            )}
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={(
              <Box pad="medium">
                <Button onClick={handleSignOut} label="Cerrar sesion" />
              </Box>
            )}
          />
        </Box>
      </Topbar.Right>
    </Topbar>
  );
};

export default SignOutTopbar;
