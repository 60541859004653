import { Nav } from 'grommet';
import { Blank } from 'grommet-icons';
import React from 'react';
import { MessageSquare, Package } from 'react-feather';

import NavbarItem from './NavbarItem';

const Navbar = () => (
  <Nav
    direction="row"
    background="white"
    fill="horizontal"
    gap="none"
  >
    <NavbarItem
      to="/dashboard"
      label="Bandeja"
      icon={iconProps => <Blank {...iconProps}><Package color="" /></Blank>}
    />
    <NavbarItem
      to="/messages"
      label="Mensajes"
      icon={iconProps => <Blank {...iconProps}><MessageSquare color="" /></Blank>}
      bubble={false}
    />
  </Nav>
);

export default Navbar;
