import PropTypes from 'prop-types';

export const messageShape = {
  id: PropTypes.string,
  conversation: PropTypes.string,
  sender: PropTypes.string,
  body: PropTypes.string,
  dateCreated: PropTypes.string,
};

export const conversationShape = {
  sid: PropTypes.string,
  participant: PropTypes.shape({
    identity: PropTypes.string,
    name: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(PropTypes.shape(messageShape)),
};
