import { Box } from 'grommet';

import BackButton from './BackButton';
import Topbar from './Topbar';

const FlatBackTopbar = () => (
  <Topbar pad="medium" background="transparent">
    <Topbar.Left>
      <Box round="full" overflow="hidden">
        <BackButton />
      </Box>
    </Topbar.Left>
  </Topbar>
);

export default FlatBackTopbar;
