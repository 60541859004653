import { useLazyQuery } from '@apollo/client';
import {
  Box, Heading,
} from 'grommet';
import React, { useEffect } from 'react';
import shortid from 'shortid';

import noOrdersImage from '../../assets/no-orders.svg';
import Announcement from '../../components/Announcement';
import Body from '../../components/Body';
import Layout from '../../components/Layout';
import OrderCard from '../../components/OrderCard';
import ProfileCard from '../../components/ProfileCard';
import SignOutTopbar from '../../components/SignOutTopbar';
import { GET_STORE_SETTING } from '../../graphql/queries';
import realmApp from '../../services/realm';

const orders = [
  // {
  //   customer: 'John Ston',
  //   timestamp: '2020-11-22T00:00:00+00:00',
  //   total: 80.32,
  //   done: false,
  // },
  // {
  //   id: 532,
  //   customer: 'Perpetua Ntulila',
  //   timestamp: '2020-11-08T00:00:00+00:00',
  //   total: 64.32,
  //   done: false,
  // },
  // {
  //   id: 126,
  //   customer: 'James Moliette',
  //   timestamp: '2020-10-22T00:00:00+00:00',
  //   total: 25.32,
  //   done: true,
  // },
];
const Dashboard = () => {
  const { id } = realmApp.currentUser;
  const [getStoreSetting, { data }] = useLazyQuery(GET_STORE_SETTING, {
    variables: {
      userId: id,
    },
  });

  useEffect(() => getStoreSetting(), [getStoreSetting]);

  const {
    publicName: profilePublicName,
    photo: profilePhoto,
  } = data?.profile || {};
  const { name: storeName } = data?.store || {};

  return (
    <Layout
      navbar
      topbarComponent={<SignOutTopbar />}
    >
      <Body gap="xlarge">
        <ProfileCard
          avatar={profilePhoto}
          name={storeName}
          subheader={profilePublicName}
        />
        <Box gap="medium" fill>
          <Heading level={2} margin="none">
            Pedidos
          </Heading>
          {orders.length
            ? orders.map(order => (
              <OrderCard
                key={shortid.generate()}
                title={order.customer}
                timestamp={order.timestamp}
                total={order.total}
                done={order.done}
              />
            ))
            : (
              <Box fill justify="center" height={{ min: '330px' }}>
                <Announcement image={noOrdersImage} message="Aun no tienes pedidos" />
              </Box>
            )}

        </Box>
      </Body>
    </Layout>
  );
};

export default Dashboard;
