import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import realmApp from '../services/realm';

const AuthRoute = ({ component: Component, isUser, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => (realmApp.currentUser ? (
      <Component />
    ) : (
      <Redirect
        to={{
          pathname: '/auth/signin',
          state: { from: location },
        }}
      />
    ))}
  />
);

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isUser: PropTypes.bool,
};

AuthRoute.defaultProps = {
  isUser: false,
};

export default AuthRoute;
