import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const Badge = ({ text, ...props }) => (
  <Box flex={false} {...props}>
    <Box background="brand" width="30px" height="30px" round="full">
      <Text margin="auto" textAlign="center" size="medium" color="white">
        {text}
      </Text>
    </Box>
  </Box>
);

Badge.propTypes = {
  text: PropTypes.number.isRequired,
};

export default Badge;
