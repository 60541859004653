import { Box, Text, ThemeContext } from 'grommet';
import PropTypes from 'prop-types';
import * as React from 'react';

const ErrorMessage = ({ message }) => <Text color="status-error">{message}</Text>;

const FieldBox = ({
  children, label, error, ...props
}) => {
  const errorExtends = {
    global: {
      control: {
        border: {
          color: 'status-error',
          width: '2px',
        },
      },
    },
  };

  return (
    <Box {...props}>
      <ThemeContext.Extend value={error ? errorExtends : {}}>
        <Text as="label">
          <Box margin={{ bottom: 'xsmall', left: 'xsmall' }}>
            <Text
              weight="bold"
              size="small"
            >
              {label}
            </Text>
          </Box>
          {children}
          {error && <ErrorMessage message={error.message} />}
        </Text>
      </ThemeContext.Extend>
    </Box>
  );
};

FieldBox.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

FieldBox.defaultProps = {
  label: undefined,
  error: undefined,
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: undefined,
};

export default FieldBox;
