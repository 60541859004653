import {
  TextInput, Box, ThemeContext,
} from 'grommet';
import { Blank } from 'grommet-icons';
import React, { forwardRef, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

import RoutedButton from './RoutedButton';

const PasswordInput = forwardRef((props, ref) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Box
          border={{
            color: theme.global.control.border.color,
            size: theme.global.control.border.width,
          }}
          round={theme.global.control.border.radius}
          background="white"
          direction="row"
          align="center"
          fill="horizontal"
        >
          <TextInput
            {...props}
            ref={ref}
            type={isVisible ? 'text' : 'password'}
            plain
          />
          <RoutedButton
            margin={{ horizontal: 'small' }}
            onClick={() => setVisible(!isVisible)}
            icon={(
              <Blank color="dark-3">
                {isVisible ? <EyeOff color="" /> : <Eye color="" />}
              </Blank>
            )}
          />
        </Box>
      )}
    </ThemeContext.Consumer>
  );
});

export default PasswordInput;
