import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import realmApp from './realm';

const httpLink = createHttpLink({
  uri: 'https://realm.mongodb.com/api/client/v2.0/app/trato-dqlma/graphql',
});

const authLink = setContext((_, { headers }) => {
  console.log('authlink called');
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${realmApp.currentUser.accessToken}`,
    },
  };
});

export default new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
