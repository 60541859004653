/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box, Button, TextInput,
} from 'grommet';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// import defaultAvatar from '../../assets/default-avatar.png';
import BackButton from '../../components/BackButton';
import Body from '../../components/Body';
import ChangeAvatar from '../../components/ChangeAvatar';
import FieldBox from '../../components/FieldBox';
import Frame from '../../components/Frame';
import Scrollable from '../../components/Scrollable';
import Topbar from '../../components/Topbar';
import { SAVE_STORE_SETTING } from '../../graphql/mutations';
import { GET_STORE_SETTING } from '../../graphql/queries';
import realmApp from '../../services/realm';

const EditProfile = () => {
  const [busy, setBusy] = useState(false);
  const history = useHistory();
  const { id } = realmApp.currentUser;
  const [saveStoreSetting] = useMutation(SAVE_STORE_SETTING);
  const [getStoreSetting, { data }] = useLazyQuery(GET_STORE_SETTING, {
    variables: {
      userId: id,
    },
  });

  useEffect(() => getStoreSetting(), [getStoreSetting]);

  const handleSave = async values => {
    console.log(values);
    setBusy(true);
    try {
      await saveStoreSetting({
        variables: {
          userId: id,
          storeName: values.storeName,
          profilePublicName: values.publicName,
          profilePhoto: values.photo,
        },
        refetchQueries: [{ query: GET_STORE_SETTING, variables: { userId: id } }],
      });
      history.push('/dashboard');
    } catch (e) {
      setBusy(false);
      // eslint-disable-next-line no-console
      console.error(e);
      // TODO: UX
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    errors,
  } = useForm({
    defaultValues: {
      photo: '',
      publicName: '',
      storeName: '',
    },
  });
  const {
    publicName: profilePublicName,
    photo: profilePhoto,
  } = data?.profile || {};
  const { name: storeName } = data?.store || {};

  useEffect(() => {
    setValue('photo', profilePhoto);
    setValue('publicName', profilePublicName);
    setValue('storeName', storeName);
  }, [profilePhoto, profilePublicName, setValue, storeName]);

  return (
    <Frame>
      <Topbar>
        <Topbar.Left>
          <BackButton />
        </Topbar.Left>
      </Topbar>
      <Scrollable>
        <Body gap="large">
          <Box
            as="form"
            gap="medium"
            onSubmit={handleSubmit(handleSave)}
          >
            <Controller
              name="photo"
              as={ChangeAvatar}
              control={control}
            />
            <FieldBox label="Nombre de la tienda" error={errors?.storeName}>
              <TextInput
                name="storeName"
                aria-label="Nombre de la tienda"
                placeholder="Nombre publico comercial"
                ref={register({
                  required: 'Requerido',
                })}
              />
            </FieldBox>
            <FieldBox label="Nombre del dependiente" error={errors?.publicName}>
              <TextInput
                name="publicName"
                aria-label="Nombre del dependiente"
                placeholder="Ejemplo: Carolina"
                ref={register({
                  required: 'Requerido',
                })}
              />
            </FieldBox>
            <Button
              primary
              label="Guardar"
              type="submit"
              disabled={busy}
            />
          </Box>
        </Body>
      </Scrollable>
    </Frame>
  );
};

export default EditProfile;
