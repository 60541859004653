/* eslint-disable react/prop-types */
import {
  Stack, Avatar, Box, Button,
} from 'grommet';
import { Blank } from 'grommet-icons';
import nop from 'nop';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Camera } from 'react-feather';
import Resizer from 'react-image-file-resizer';
import styled from 'styled-components';

import defaultAvatar from '../assets/default-avatar.png';

const StyleAvatar = styled(Avatar)`
  opacity: 0.8;
  filter: brightness(50%);
`;

const StyledInput = styled('input')`
  display: none;
`;

const resize = async file => new Promise(
  resolve => {
    Resizer.imageFileResizer(
      file,
      200,
      200,
      'JPEG',
      100,
      0,
      uri => resolve(uri),
      'base64',
    );
  },
);

const ChangeAvatar = forwardRef(({
  disabled,
  onChange,
  value,
}, ref) => {
  const handleClick = () => ref.current.click();

  const clearPhoto = async () => onChange('');

  const handleChange = async event => {
    const { files } = event.target;
    if (files.length) {
      const uri = await resize(files[0]);
      onChange(uri);
    }
  };

  return (
    <Box justify="center" align="center" flex={false}>
      <Stack anchor="center" justify="center" align="center">
        {/* {console.log('value es', value)} */}
        <StyleAvatar src={value || defaultAvatar} size="xlarge" />
        <Box round="full" overflow="hidden">
          <Button
            primary
            disabled={disabled}
            onClick={handleClick}
            icon={(
              <Blank>
                <Camera color="" />
              </Blank>
            )}
          />
        </Box>
      </Stack>
      <StyledInput
        type="file"
        accept="image/*"
        onChange={handleChange}
        ref={ref}
      />
      <Box margin="small">
        <Button
          secondary
          onClick={clearPhoto}
          label="Eliminar foto"
        />
      </Box>
    </Box>
  );
});

ChangeAvatar.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

ChangeAvatar.defaultProps = {
  value: '',
  disabled: false,
  onChange: nop,
};

export default ChangeAvatar;
