import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const Body = ({ children, ...props }) => (
  <Box margin="large" fill="vertical" {...props}>
    {children}
  </Box>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;
