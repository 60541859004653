import { ApolloProvider } from '@apollo/client';
import { Grommet } from 'grommet';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import styled from 'styled-components';

import App from './App';
import GlobalStyle from './GlobalStyle';
import reportWebVitals from './reportWebVitals';
import client from './services/apollo';
import theme from './theme';

const StyledGrommet = styled(Grommet)`
  height: 100%;
`;

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <StyledGrommet theme={theme} themeMode="light">
            <GlobalStyle />
            <App />
          </StyledGrommet>
        </BrowserRouter>
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
