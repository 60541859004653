import {
  Box, Button, Heading, TextInput,
} from 'grommet';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Realm from 'realm-web';

import Body from '../../components/Body';
import ErrorBox from '../../components/ErrorBox';
import FlatBackTopbar from '../../components/FlatBackTopbar';
import Layout from '../../components/Layout';
import PasswordInput from '../../components/PasswordInput';
import RoutedButton from '../../components/RoutedButton';
import realmApp from '../../services/realm';

const signInErrors = {
  InvalidPassword: 'El correo o la contraseña son erroneos',
  AuthError: 'Introduce tu correo y contraseña',
};

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const handleSignIn = async event => {
    event.preventDefault();
    setLoading(true);
    const credentials = Realm.Credentials.emailPassword(email, password);

    try {
      await realmApp.logIn(credentials);
      history.push('/');
    } catch (e) {
      setError(signInErrors[e.errorCode]);
      setLoading(false);
    }
  };

  return (
    <Layout topbarComponent={<FlatBackTopbar />}>
      <Body gap="large">
        <Heading level={1} size="large">Inicia sesión</Heading>
        <form onSubmit={handleSignIn}>
          <Box
            gap="medium"
            flex={false}
          >
            {error && <ErrorBox message={error} />}
            <TextInput
              value={email}
              onChange={e => setEmail(e.target.value)}
              aria-label="Correo electrónico"
              placeholder="Correo electrónico"
            />
            <PasswordInput
              value={password}
              onChange={e => setPassword(e.target.value)}
              aria-label="Contraseña"
              placeholder="Contraseña"
            />

            <Button
              primary
              label="Continuar"
              type="submit"
              disabled={loading}
            />
          </Box>
        </form>
        <Box
          direction="row"
          margin={{ vertical: 'xlarge' }}
          justify="center"
          gap="xsmall"
          flex={false}
        >
          ¿Eres nuevo por aquí?
          <RoutedButton
            label="Regístrate"
            href="/auth/signup"
          />
        </Box>
      </Body>
    </Layout>
  );
};

export default SignIn;
