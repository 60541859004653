import { Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = ({ href, ...props }) => {
  const history = useHistory();
  const backFn = () => history.goBack();

  return (
    <Button
      primary
      onClick={backFn}
      icon={<LinkPrevious />}
      {...props}
    />
  );
};

BackButton.propTypes = {
  href: PropTypes.string,
};

BackButton.defaultProps = {
  href: undefined,
};

export default BackButton;
