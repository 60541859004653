/* eslint-disable no-unused-vars */
import { useRecoilValue } from 'recoil';

import { Conversations } from './selectors';

const useConversationsList = () => {
  const conversations = useRecoilValue(Conversations);

  // useEffect(() => {
  //   Promise.all(
  //     conversations.map(async conversation => {
  //       const participant = (await conversation.getParticipants())
  // .find(p => p.identity !== user);
  //       return {
  //         sid: conversation.sid,
  //         participant: participant.identity,
  //         messages: (await conversation.getMessages(1)).items,
  //       };
  //     }),
  //   ).then(val => setComposedConversation(val));
  // }, [conversations, user]);

  // console.log(conversations);
  // const handleConversationUpdated = useCallback(async conversation => {
  //   const index = serialisedConversations.findIndex(c => c.sid !== conversation.sid);
  //   const newConvo = {
  //     sid: conversation.sid,
  //     lastMessage: await conversation.getMessages(1),
  //   };
  //   // eslint-disable-next-line no-debugger
  //   debugger;
  //   const newserialisedConversations = Object.assign([],
  // serialisedConversations, { [index]: newConvo });
  //   setserialisedConversations(newserialisedConversations);
  // }, [serialisedConversations]);

  // useEffect(() => {
  //   console.log('run', client);
  //   if (client) {
  //     conversations.forEach(async sid => {
  //       console.log('loop', sid);
  //       const twilioConversation = await client.getConversationBySid(sid);
  //       handleConversationUpdated(twilioConversation);
  //       // twilioConversation.on('updated', handleConversationUpdated);
  //     });
  //   }
  // }, [client, conversations, handleConversationUpdated]);

  return conversations;
};

export default useConversationsList;
