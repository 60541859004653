import dayjs from 'dayjs';
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthRoute from './components/AuthRoute';
import SignIn from './views/auth/SignIn';
import SignUp from './views/auth/SignUp';
import SendCheckout from './views/checkout/SendCheckout';
import Conversation from './views/conversation/Conversation';
import ConversationList from './views/conversation/ConversationList';
import Dashboard from './views/dashboard/Dashboard';
import EditProfile from './views/dashboard/EditProfile';

dayjs.extend(relativeTime);
dayjs.locale('es');

const App = () => (
  <Switch>
    <AuthRoute exact path="/dashboard" component={Dashboard} />
    <AuthRoute exact path="/dashboard/edit-profile" component={EditProfile} />

    <AuthRoute exact path="/messages" component={ConversationList} />
    <AuthRoute exact path="/messages/:id" component={Conversation} />

    <AuthRoute exact path="/checkout/send/:id" component={SendCheckout} />

    <Route exact path="/auth/signin" component={SignIn} />
    <Route exact path="/auth/signup" component={SignUp} />

    <Route path="/" render={() => <Redirect to="/dashboard" />} />
  </Switch>
);

export default App;
