import { Box, Image, Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const Announcement = ({ image, message }) => (
  <Box align="center" gap="xlarge">
    <Image width="75%" src={image} fit="contain" />
    <Text textAlign="center" color="dark-3">
      {message}
    </Text>
  </Box>
);

Announcement.propTypes = {
  image: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
};

export default Announcement;
