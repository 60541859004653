import { Box, Stack, Text } from 'grommet';
import nop from 'nop';
import PropTypes from 'prop-types';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import RoutedButton from '../RoutedButton';

const NavbarItem = ({
  to, label, icon: Icon, bubble,
}) => {
  const isActive = useRouteMatch({ path: to });
  const color = isActive ? 'brand-contrast' : 'text';

  return (
    <Box
      pad="small"
      width="full"
      align="center"
    >
      <RoutedButton href={to} fill="horizontal">
        <Stack
          anchor="top-right"
          interactiveChild="first"
        >
          <Box align="center" gap="xsmall">
            <Icon color={color} />
            <Text weight="bold" size="xsmall" color={color}>{label}</Text>
          </Box>
          {bubble && (
          <Box
            margin="xsmall"
            background="brand"
            width="15px"
            height="15px"
            round
          />
          )}
        </Stack>
      </RoutedButton>
    </Box>
  );
};

NavbarItem.defaultProps = {
  to: '',
  label: '',
  icon: nop,
  bubble: false,
};

NavbarItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.elementType,
  bubble: PropTypes.bool,
};

export default NavbarItem;
