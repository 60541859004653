import PropTypes from 'prop-types';
import React from 'react';

import Frame from './Frame';
import Navbar from './Navbar';
import Scrollable from './Scrollable';

const Layout = ({ children, navbar, topbarComponent }) => (
  <Frame>
    {topbarComponent && topbarComponent}
    <Scrollable>
      {children}
    </Scrollable>
    { navbar && <Navbar /> }
  </Frame>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navbar: PropTypes.bool,
  topbarComponent: PropTypes.node,
};

Layout.defaultProps = {
  navbar: false,
  topbarComponent: false,
};

export default Layout;
