import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

const Topbar = ({ children, ...props }) => (
  <Box
    pad={{ vertical: 'small', horizontal: 'xsmall' }}
    background="brand"
    direction="row"
    {...props}
  >
    {children}
  </Box>
);

Topbar.Left = ({ children }) => (
  <Box direction="row" margin={{ right: 'auto' }}>
    {children}
  </Box>
);

Topbar.Right = ({ children }) => (
  <Box direction="row" margin={{ left: 'auto' }}>
    {children}
  </Box>
);

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = { children: [] };

Topbar.propTypes = propTypes;
Topbar.defaultProps = defaultProps;
Topbar.Left.propTypes = propTypes;
Topbar.Left.defaultProps = defaultProps;
Topbar.Right.propTypes = propTypes;
Topbar.Right.defaultProps = defaultProps;

export default Topbar;
