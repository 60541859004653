import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import * as React from 'react';

const ErrorBox = ({ message }) => (
  <Box
    background="status-error"
    pad="small"
    round="xsmall"
    margin={{ bottom: 'small' }}
  >
    <Text size="small" color="white">{message}</Text>
  </Box>
);

ErrorBox.propTypes = {
  message: PropTypes.string,
};

ErrorBox.defaultProps = {
  message: undefined,
};

export default ErrorBox;
