import { Box, TextInput } from 'grommet';
import { FormNext } from 'grommet-icons';
import nop from 'nop';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from './IconButton';

const ChatInput = ({
  placeholder, value, onChange, onSend, ...props
}) => (
  <Box
    width="full"
    direction="row"
    pad="small"
    gap="small"
    background="white"
    align="center"
  >
    <TextInput
      value={value}
      onChange={onChange}
      onKeyPress={e => e.key === 'Enter' && onSend()}
      size="medium"
      placeholder={placeholder}
      plain
      {...props}
    />
    <IconButton onClick={onSend} icon={<FormNext color="white" />} primary />
  </Box>
);

ChatInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSend: PropTypes.func,
};

ChatInput.defaultProps = {
  placeholder: '',
  value: '',
  onChange: nop,
  onSend: nop,
};

export default ChatInput;
