import TwilioConversations from '@twilio/conversations';

class Conversations {
  async create(tokenFetcher) {
    this.clientInstance = await TwilioConversations.create(tokenFetcher);
    return this.client;
  }
  get client() {
    return this.clientInstance;
  }
}

export default new Conversations();
