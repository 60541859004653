/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SAVE_STORE_SETTING = gql`
  mutation ($userId: String!, $storeName: String!, $profilePublicName: String!, $profilePhoto: String) {
    saveStoreSetting (input: {
      storeName: $storeName,
      profilePhoto: $profilePhoto
      profilePublicName: $profilePublicName,
      userId: $userId
    }) { 
      status
    }
  }
`;
