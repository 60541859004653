/* eslint-disable no-unused-vars */
import { Box } from 'grommet';
import React, { Suspense } from 'react';
import { CreditCard } from 'react-feather';
import { useParams } from 'react-router-dom';

import { useConversation } from '../../Chat';
import BackButton from '../../components/BackButton';
import ChatBox from '../../components/ChatBox';
import ContactState from '../../components/ContactState';
import Layout from '../../components/Layout';
import RoutedButton from '../../components/RoutedButton';
import Topbar from '../../components/Topbar';

const Conversation = () => {
  const { id: identity } = useParams();
  const [conversation, sendMessage] = useConversation(identity);
  const { participant } = conversation || {};

  return (
    <Layout
      topbarComponent={(
        <Topbar background="white">
          <Topbar.Left>
            <BackButton />
            <Box>
              <ContactState
                name={participant?.name}
              />
            </Box>
          </Topbar.Left>
        </Topbar>
    )}
    >
      <Box background="brand">
        <RoutedButton
          primary
          round={false}
          icon={<CreditCard />}
          label="Solicitar pago"
          href={`/checkout/${identity}`}
        />
      </Box>
      <ChatBox
        conversation={conversation}
        onSend={sendMessage}
        notifyReceipt={() => {}}
      />
    </Layout>
  );
};

export default Conversation;
