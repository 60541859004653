import { Avatar, Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';

import defaultAvatar from '../assets/default-avatar.png';

const ContactState = ({
  name, state, avatar, avatarVisible,
}) => (
  <Box
    direction="row"
    justify="start"
    gap="medium"
    pad={{ horizontal: 'small' }}
  >
    { avatarVisible && <Avatar src={avatar} size="small" /> }
    <Box justify="start">
      <Text textAlign="start" size="large" fill="horizontal">
        {name}
      </Text>
      {state && (
        <Text color="dark-3" size="small">
          {state}
        </Text>
      )}
    </Box>
  </Box>
);

ContactState.propTypes = {
  name: PropTypes.string.isRequired,
  state: PropTypes.string,
  avatar: PropTypes.string,
  avatarVisible: PropTypes.bool,
};

ContactState.defaultProps = {
  state: undefined,
  avatar: defaultAvatar,
  avatarVisible: false,
};

export default ContactState;
