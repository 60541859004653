import dayjs from 'dayjs';
import {
  Box, Card, Text,
} from 'grommet';
import { Blank } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Check, ChevronRight, Clock } from 'react-feather';

const OrderCard = ({
  timestamp, title, total, done,
}) => {
  const date = dayjs(timestamp).fromNow();
  return (
    <Card
      background="white"
      direction="row"
      pad="large"
      gap="large"
      align="center"
    >
      <Box
        justify="center"
        flex={false}
      >
        <Box
          width="36px"
          height="36px"
          background={done ? 'brand' : 'status-warning'}
          round="full"
          justify="center"
          align="center"
        >
          <Blank color="white">
            { done ? <Check color="" /> : <Clock color="" /> }
          </Blank>
        </Box>
      </Box>

      <Box basis="full">
        <Text
          truncate
          size="large"
        >
          {title}
        </Text>
        <Text
          truncate
          weight="bold"
          size="xsmall"
          color="dark-3"
        >
          {date}
        </Text>
      </Box>
      <Text size="large">
        {total}
        €
      </Text>
      <Box flex={false}>
        <Blank>
          <ChevronRight color="" />
        </Blank>
      </Box>
    </Card>
  );
};

OrderCard.propTypes = {
  timestamp: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  done: PropTypes.bool,
};

OrderCard.defaultProps = {
  timestamp: dayjs().toJSON(),
  title: '',
  total: 0,
  done: false,
};

export default OrderCard;
