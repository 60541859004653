import memoize from 'lodash.memoize';
import { selector } from 'recoil';

import ChatState from './atoms';

export const Connection = selector({
  key: 'Connection',
  set: ({ get, set }, value) => {
    set(ChatState, { ...get(ChatState), connection: value });
  },
  get: ({ get }) => get(ChatState).connection,
});

export const Conversations = selector({
  key: 'Conversations',
  set: ({ get, set }, { type, value }) => {
    const chatState = get(ChatState);

    switch (type) {
      case 'ADD': {
        set(ChatState, {
          ...chatState,
          conversations: chatState.conversations.concat(value),
        });
        break;
      }
      case 'REMOVE': {
        set(ChatState, {
          ...chatState,
          conversations: chatState.conversations.filter(convSid => convSid !== value),
        });
        break;
      }
      default:
    }
  },
  get: ({ get }) => get(ChatState).conversations,
});

export const Messages = selector({
  key: 'Messages',
  set: ({ get, set }, { type, value }) => {
    const chatState = get(ChatState);

    switch (type) {
      case 'ADD': {
        set(ChatState, {
          ...chatState,
          conversations: chatState.conversations
            .map(conv => (conv.sid === value.conversation ? {
              ...conv,
              messages: conv.messages.concat(value),
            } : { ...conv })),
        });
        break;
      }
      default:
    }
  },
});

export const ConversationWithParticipant = memoize(identity => selector({
  key: `ConversationWithParticipant-${identity}`,
  get: ({ get }) => get(Conversations).find(conv => conv.participant.identity === identity),
}));
